/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { Grid, makeStyles } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { selectRegimesTributacao } from '../../features/regime-tributacao/regimeTributacaoSlice';
import { selectTiposDeclaracao } from '../../features/tipo-declaracao/tipoDeclaracaoSlice';
import QCXFinalAlternativeCurrencyField from '../final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalPercentualField from '../final-percentual-field/QCXFinalPercentualField';
import QCXSelectDispositivoLegalAutocomplete from '../final-select-dispositivo-legal-field/QCXSelectDispositivoLegalAutocomplete';
import QCXFinalSelectTipoReducaoAutocomplete from '../final-select-tipo-reducao-field/QCXFinalSelectTipoReducaoAutocomplete';
import QCXSelectRegimeTributacaoAutocomplete from '../select-regime-tributacao/QCXSelectRegimeTributacaoAutocomplete';
import QCXFinalTextField from '../final-text-field/QCXFinalTextField';
import QCXFormTitle from '../../components/form-title/QCXFormTitle';
import QCXFinalNumericDecimalField from '../final-numeric-decimal-field/QCXFinalNumericDecimalField';

import {
  configureGeneralRegimeTributacaoMatcherWith,
  REGIME_TRIBUTACAO_DIFERIDO_CODE,
  REGIME_TRIBUTACAO_EXONERADO_CODE,
  REGIME_TRIBUTACAO_NAO_INCIDENCIA_CODE,
  REGIME_TRIBUTACAO_RECOLHIMENTO_INTEGRAL_CODE,
  REGIME_TRIBUTACAO_REDUCAO_CODE,
  REGIME_TRIBUTACAO_SUSPENSAO_CODE,
} from '../select-regime-tributacao/regimeTributacaoUtils';
import {
  configureGeneralTipoDeclaracaoMatcherWith,
  TIPO_DECLARACAO_CONSUMO_E_ADMISSAO_TEMPORARIA,
} from '../select-tipo-declaracao/tipoDeclaracaoUtils';
import QCXFinalCheckboxField from '../final-checkbox-field/QCXFinalCheckboxField';

const useStyles = makeStyles(() => ({
  gridFields: {
    paddingBottom: '16px',
  },
}));

export default function QCXIcmsFormGroup({ intl, modes, submitSucceeded, parentModel }) {
  const { t } = useTranslation();

  const classes = useStyles();

  const { values } = useFormState();

  const regimesTributacao = useSelector(selectRegimesTributacao);
  const tiposDeclaracao = useSelector(selectTiposDeclaracao);

  const regimeTributacao = useMemo(() => values?.icms?.regimeTributacao?.id, [values]);

  const tipoDeclaracao = useMemo(() => parentModel?.atributosAdicionais?.tipoDeclaracao?.id, [parentModel]);

  const isSomeRegimeTributacaoBy = useCallback(
    (codesToCompare) =>
      configureGeneralRegimeTributacaoMatcherWith(regimesTributacao)(regimeTributacao, codesToCompare),
    [regimeTributacao, regimesTributacao]
  );
  const isSomeTipoDeclaracaoBy = useCallback(
    (codesToCompare) => configureGeneralTipoDeclaracaoMatcherWith(tiposDeclaracao)(tipoDeclaracao, codesToCompare),
    [tiposDeclaracao, tipoDeclaracao]
  );
  const { isConsult, isSubConsult, isSubNone } = modes;

  return (
    <>
      <Grid item className={classes.gridFields} xs={6} sm={6} md={6} lg={6}>
        <QCXSelectRegimeTributacaoAutocomplete
          id="select-field-regime-tributacao-icms"
          key="select-field-regime-tributacao-icms"
          name="icms.regimeTributacao.id"
          label={t('com.muralis.qcx.regimeTributacao')}
          disabled={isConsult || isSubConsult || isSubNone}
          initialValues={values}
        />
      </Grid>
      <Grid item className={classes.gridFields} xs={6} sm={6} md={6} lg={6}>
        <QCXFinalAlternativeCurrencyField
          id="currency-valor-base-calculo-real-field-icms"
          key="currency-valor-base-calculo-real-field-icms"
          name="icms.baseCalculoReal"
          label={t('com.muralis.qcx.baseCalculoReais')}
          intl={intl}
          disabled
          submitSucceeded={submitSucceeded}
          adorned
          scale={7}
        />
      </Grid>
      {!isSomeRegimeTributacaoBy([REGIME_TRIBUTACAO_NAO_INCIDENCIA_CODE]) && (
        <>
          <Grid item className={classes.gridFields} xs={6} sm={6} md={6} lg={6} />
          <Grid item container className={classes.gridFields} xs={6} sm={6} md={6} lg={6}>
            <Grid item xs={values?.icms?.recolhimentoAntecipado ? 6 : 12}>
              <QCXFinalPercentualField
                id="percentual-original-icms-field-icms"
                key="percentual-original-icms-field-icms"
                name="icms.aliquotaOriginal"
                label={t('com.muralis.qcx.aliquotaICMS')}
                disabled={isConsult || isSubConsult || isSubNone}
                scale={7}
              />
            </Grid>
            {values?.icms?.recolhimentoAntecipado && (
              <Grid item xs={6} style={{ paddingLeft: '16px' }}>
                <QCXFinalPercentualField
                  id="aliquota-antecipada-field-icms"
                  key="aliquota-antecipada-field-icms"
                  name="icms.aliquotaAntecipadaPercentual"
                  label={t('com.muralis.qcx.aliquotaAntecipadaPercentual')}
                  disabled={isConsult || isSubConsult || isSubNone}
                  scale={7}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
      <Grid item className={classes.gridFields} xs={6} sm={6} md={6} lg={6}>
        {!isSomeRegimeTributacaoBy([
          REGIME_TRIBUTACAO_RECOLHIMENTO_INTEGRAL_CODE,
          REGIME_TRIBUTACAO_EXONERADO_CODE,
        ]) && (
          <QCXSelectDispositivoLegalAutocomplete
            id="select-field-dispositivo-legal-icms"
            key="select-field-dispositivo-legal-icms"
            name="icms.dispositivoLegal.id"
            label={t('com.muralis.qcx.dispositivoLegal.label')}
            disabled={isConsult || isSubConsult || isSubNone}
            initialValues={values}
          />
        )}
      </Grid>
      {!isSomeRegimeTributacaoBy([REGIME_TRIBUTACAO_NAO_INCIDENCIA_CODE]) && (
        <Grid item className={classes.gridFields} xs={6} sm={6} md={6} lg={6}>
          <QCXFinalPercentualField
            id="percentual-fecp-icms-field-icms"
            key="percentual-fecp-icms-field-icms"
            name="icms.aliquotaFecp"
            label={t('com.muralis.qcx.aliquotaFECP')}
            disabled={isConsult || isSubConsult || isSubNone}
            scale={7}
          />
        </Grid>
      )}
      <Grid item className={classes.gridFields} xs={6} sm={6} md={6} lg={6} />
      {!isSomeRegimeTributacaoBy([REGIME_TRIBUTACAO_NAO_INCIDENCIA_CODE]) && (
        <Grid item className={classes.gridFields} xs={6} sm={6} md={6} lg={6}>
          <QCXFinalPercentualField
            id="percentual-icms-field-icms"
            key="percentual-icms-field-icms"
            name="icms.aliquota"
            label={t('com.muralis.qcx.aliquotaTotalICMS')}
            disabled
            scale={7}
          />
        </Grid>
      )}

      {isSomeRegimeTributacaoBy([REGIME_TRIBUTACAO_DIFERIDO_CODE]) && (
        <Grid item className={classes.gridFields} xs={6} sm={6} md={6} lg={6}>
          <QCXFinalCheckboxField
            id="recolhimento-antecipado-field-icms"
            key="recolhimento-antecipado-field-icms"
            name="icms.recolhimentoAntecipado"
            label={t('com.muralis.qcx.recolhimentoAntecipado')}
            disabled={isConsult || isSubConsult || isSubNone}
            initialValues={values}
          />
        </Grid>
      )}
      {isSomeRegimeTributacaoBy([REGIME_TRIBUTACAO_DIFERIDO_CODE]) && (
        <Grid item className={classes.gridFields} xs={6} sm={6} md={6} lg={6} />
      )}

      {isSomeRegimeTributacaoBy([REGIME_TRIBUTACAO_REDUCAO_CODE, REGIME_TRIBUTACAO_DIFERIDO_CODE]) &&
        !values?.icms.recolhimentoAntecipado && (
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <QCXFinalSelectTipoReducaoAutocomplete
              id="select-field-tipo-de-reducao-icms"
              key="select-field-tipo-de-reducao-icms"
              name="icms.tipoReducao"
              label={t('com.muralis.qcx.tipoReducao.label')}
              initialValues={values}
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
        )}

      {isSomeRegimeTributacaoBy([REGIME_TRIBUTACAO_REDUCAO_CODE, REGIME_TRIBUTACAO_DIFERIDO_CODE]) &&
        !values?.icms.recolhimentoAntecipado && (
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <QCXFinalPercentualField
              id="percentual-icms-field-icms"
              key="percentual-icms-field-icms"
              name="icms.percentualReducao"
              label={t('com.muralis.qcx.reducaoICMS')}
              disabled={isConsult || isSubConsult || isSubNone}
              scale={7}
            />
          </Grid>
        )}
      {isSomeRegimeTributacaoBy([REGIME_TRIBUTACAO_REDUCAO_CODE]) && (
        <Grid item className={classes.gridFields} xs={6} sm={6} md={6} lg={6}>
          <QCXFinalCheckboxField
            id="resolucao-sfa-flag"
            key="resolucao-sfa-flag"
            name="icms.resolucaoSF4"
            label={t('com.muralis.qcx.resolucaoSF4')}
            disabled={isConsult || isSubConsult || isSubNone}
            initialValues={values}
          />
        </Grid>
      )}

      {isSomeRegimeTributacaoBy([REGIME_TRIBUTACAO_REDUCAO_CODE]) && values?.icms.resolucaoSF4 && (
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <QCXFinalPercentualField
            id="percentual-icms-field-icms"
            key="percentual-icms-field-icms"
            name="icms.percentualBeneficio"
            label={t('com.muralis.qcx.percentualBeneficio')}
            disabled={isConsult || isSubConsult || isSubNone}
            scale={7}
          />
        </Grid>
      )}

      <Grid item xs={12} sm={6} md={6} lg={6}>
        <QCXFinalAlternativeCurrencyField
          id="valor-devido-reais-tarifario-field-icms"
          key="valor-devido-reais-tarifario-field-icms"
          name="icms.valorDevidoReal"
          label={t('com.muralis.qcx.valorDevidoReais')}
          disabled
          adorned
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <QCXFinalAlternativeCurrencyField
          id="valor-recolher-reais-tarifario-field-icms"
          key="valor-recolher-tarifario-field-icms"
          name="icms.valorRecolherReal"
          label={t('com.muralis.qcx.valorRecolherReais')}
          disabled
          adorned
        />
      </Grid>
      {isSomeRegimeTributacaoBy([REGIME_TRIBUTACAO_SUSPENSAO_CODE]) &&
        isSomeTipoDeclaracaoBy([TIPO_DECLARACAO_CONSUMO_E_ADMISSAO_TEMPORARIA]) && (
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <QCXFinalAlternativeCurrencyField
              id="recolher-permanecia-field-icms"
              key="recolher-permanecia-field-icms"
              name="icms.recolherPermanencia"
              label={t('com.muralis.qcx.valorSuspensoReais')}
              disabled
              adorned
            />
          </Grid>
      )}

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <QCXFormTitle title="Substituição Tributária"/>
      </Grid>

      <Grid item className={classes.gridFields} xs={6} sm={6} md={6} lg={6}>
        <QCXFinalNumericDecimalField
          id="base-substituicao-tributaria"
          key="base-substituicao-tributaria"
          name="icms.baseSubstTrib"
          label="Base. Subst. Tributária"
          disabled
          scale={2}
        />
      </Grid>

      <Grid item className={classes.gridFields} xs={6} sm={6} md={6} lg={6}>
        <QCXFinalNumericDecimalField
          id="percentual-mva"
          key="percentual-mva"
          name="icms.percMva"
          label="% MVA"
          disabled={isConsult || isSubConsult || isSubNone}
          scale={2}
        />
      </Grid>

      <Grid item className={classes.gridFields} xs={6} sm={6} md={6} lg={6}>
        <QCXFinalNumericDecimalField
          id="valor-devido-subst-tributaria"
          key="valor-devido-subst-tributaria"
          name="icms.valorDevidoSubstTrib"
          label="Valor Devido Subst. Tributária"
          disabled
          scale={2}
        />
      </Grid>

      <Grid item className={classes.gridFields} xs={6} sm={6} md={6} lg={6}>
        <QCXFinalNumericDecimalField
          id="percentual-substituicao-tributaria"
          key="percentual-substituicao-tributaria"
          name="icms.substTributaria"
          label="% Substituição Tributária"
          disabled={isConsult || isSubConsult || isSubNone}
          scale={2}
        />
      </Grid>


    </>
  );
}
