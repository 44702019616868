const selectDanfes = (state) => state.danfe.list;
const selectCountDanfe = (state) => state.danfe.count;
const selectDanfe = (state) => state.danfe.model;
const selectVolumes = (state) => state.danfe.model.volumes;
const selectAdicoes = (state) => state.danfe.model.adicoes;
const selectStatus = (state) => state.danfe.status;
const selectMode = (state) => state.danfe.mode.main;
const selectBackgroundMode = (state) => state.danfe.mode.background;
const selectError = (state) => state.danfe.error;
const selectResponse = (state) => state.danfe.response;
const selectRefresh = (state) => state.danfe.refresh;
const selectRelatedSelectionAvailableItemList = (state) => (
  state.danfe.related.list.selectionAvailableItem
);
const selectRelatedSelectionChangedItemList = (state) => (
  state.danfe.related.list.selectionChangedItem
);
const selectRelatedSelectionItemList = (state) => (
  state.danfe.related.list.selectionItem
);
const selectRelatedDanfeMae = (state) => (
  state.danfe.related.model.danfeMae
);
const selectRelatedTransferenciaItemModel = (state) => (
  state.danfe.related.model.transferenciaItem
);

const danfeSelectors = {
  selectDanfes,
  selectCountDanfe,
  selectDanfe,
  selectStatus,
  selectMode,
  selectBackgroundMode,
  selectError,
  selectResponse,
  selectVolumes,
  selectAdicoes,
  selectRefresh,
  selectRelatedSelectionAvailableItemList,
  selectRelatedSelectionChangedItemList,
  selectRelatedSelectionItemList,
  selectRelatedDanfeMae,
  selectRelatedTransferenciaItemModel,
};

export {
  danfeSelectors,
  selectDanfes,
  selectCountDanfe,
  selectDanfe,
  selectMode,
  selectBackgroundMode,
  selectStatus,
  selectError,
  selectResponse,
  selectVolumes,
  selectAdicoes,
  selectRefresh,
  selectRelatedSelectionAvailableItemList,
  selectRelatedSelectionChangedItemList,
  selectRelatedSelectionItemList,
  selectRelatedDanfeMae,
  selectRelatedTransferenciaItemModel,
};
