import { createAsyncThunk } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import { LICENCA_IMPORTACAO_ENDPOINT } from '../../utils/api/domain/domain-endpoint-utils';
import { DEFAULT_TIPO_LI_PARAMETER } from '../../utils/general/fatura/faturaUtils';
import { faturaAPI } from '../fatura/faturaAPI';
import { setErrorFeedback, setSuccessFeedback } from '../feedback/feedbackSlice';
import { fetchTransmissaoLiByIdAndTipo, licencaImportacaoAPI } from './licencaImportacaoAPI';
import { isGeneratedLiMetaType } from './licencaImportacaoUtils';

const fetchAllAsync = createAsyncThunk('licencaImportacao/fetchAll', async () => {
  const { data } = await licencaImportacaoAPI.fetchAll();
  return { data };
});

const fetchByIdAsync = createAsyncThunk('licencaImportacao/fetchById', async (data) => {
  const response = await licencaImportacaoAPI.fetchById(data);
  return {
    response: {
      status: response.status,
      data: response.data,
    },
  };
});

const activateByIdAsync = createAsyncThunk('licencaImportacao/activateById', async (id) => {
  const { status, data } = await licencaImportacaoAPI.activateById(id);
  return { response: { status, data } };
});

const inactivateByIdAsync = createAsyncThunk('licencaImportacao/inactivateById', async (id) => {
  const { status, data } = await licencaImportacaoAPI.inactivateById(id);
  return { response: { status, data } };
});

const fetchFilterPaginateAsync = createAsyncThunk('licencaImportacao/fetchFilterPaginateAsync', async (params) => {
  let requestParams = [DEFAULT_TIPO_LI_PARAMETER]
  if (params && Array.isArray(params) && params.length) {
    requestParams = [...requestParams, ...params]
  }
  const { data } = await faturaAPI.fetchFilterPaginateAsync(requestParams);
  return { data };
});

const fetchAllFromFaturaAsync = createAsyncThunk('licencaImportacao/fetchAllFromFatura', async () => {
  const { data } = await faturaAPI.fetchByFilter([DEFAULT_TIPO_LI_PARAMETER]);
  return { data };
});

const fetchByIdFromFaturaAsync = createAsyncThunk('licencaImportacao/fetchByIdFromFatura', async (data) => {
  const response = await faturaAPI.fetchById(data);
  return {
    response: {
      status: response.status,
      data: response.data,
    },
  };
});

const activateByIdFromFaturaAsync = createAsyncThunk('licencaImportacao/activateByIdFromFatura', async (id) => {
  const { status, data } = await faturaAPI.activateById(id);
  return { response: { status, data } };
});

const inactivateByIdFromFaturaAsync = createAsyncThunk('licencaImportacao/inactivateByIdFromFatura', async (id) => {
  const { status, data } = await faturaAPI.inactivateById(id);
  return { response: { status, data } };
});

const updatePartialLi = createAsyncThunk('licencaImportacao/updatePartialLi', async (data) => {
  const { status, data: response } = await licencaImportacaoAPI.save(data);
  return { response: { status, data: response } };
});

const calculateByIdFromFaturaAsync = createAsyncThunk(
  'licencaImportacao/calculateByIdFromFatura',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await faturaAPI.calculateById(data?.id);

      if (response?.status === 200) {
        dispatch(setSuccessFeedback({ message: 'LI calculada com sucesso!' }));
      }

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      dispatch(setErrorFeedback({ message: error?.response?.data?.message ?? 'Erro ao calcular LI' }));
      return rejectWithValue(error.response.data);
    }
  }
);

const conferirByIdFromFaturaAsync = createAsyncThunk(
  'licencaImportacao/conferirByIdFromFatura',
  async (data, thunk) => {
    try {
      const response = await faturaAPI.conferirBy(data?.fatura?.id, [LICENCA_IMPORTACAO_ENDPOINT, data?.id]);
      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      return thunk?.rejectWithValue(error?.response?.data);
    }
  }
);

const duplicarByIdFromFaturaAsync = createAsyncThunk(
  'licencaImportacao/duplicarById',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const args = isGeneratedLiMetaType(data?.meta?.type)
        ? [data?.fatura?.id, [LICENCA_IMPORTACAO_ENDPOINT, data?.id], data?.payload]
        : [data?.id, [], data?.payload];

      const response = await faturaAPI.duplicarBy(...args);

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      dispatch(setErrorFeedback({ message: error?.response?.data?.message ?? 'Erro ao duplicar LI' }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

const gerarSubstitutivaAsync = createAsyncThunk('gerarSubstitutiva', async (data, { rejectWithValue }) => {
  try {
    const { id, faturaId } = data;

    const response = await faturaAPI.gerarSubstitutiva({ id, faturaId });
    return {
      response: {
        status: response?.status,
        data: response?.data,
        ...omit(response, ['request', 'config']),
      },
    };
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

const transmissaoLiByIdAsync = createAsyncThunk(
  'licencaImportacao/transmissaoLiById',
  async ({ data }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchTransmissaoLiByIdAndTipo(data.id, data.tipo);

      if (response?.status === 200) {
        dispatch(setSuccessFeedback({ message: response?.data }));
      }

      if (response?.status === 500 || response?.status === 404 || response?.status === 401) {
        const message = response?.data ? response?.data : 'Erro não esperado!';

        dispatch(setErrorFeedback({ message }));
      }

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      dispatch(setErrorFeedback({ message: error?.response?.data }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

const licencaImportacaoThunks = {
  fetchAllAsync,
  fetchByIdAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  fetchAllFromFaturaAsync,
  fetchByIdFromFaturaAsync,
  fetchFilterPaginateAsync,
  activateByIdFromFaturaAsync,
  inactivateByIdFromFaturaAsync,
  calculateByIdFromFaturaAsync,
  conferirByIdFromFaturaAsync,
  duplicarByIdFromFaturaAsync,
  gerarSubstitutivaAsync,
  updatePartialLi,
  transmissaoLiByIdAsync,
};

export {
  activateByIdAsync,
  activateByIdFromFaturaAsync,
  calculateByIdFromFaturaAsync,
  conferirByIdFromFaturaAsync,
  duplicarByIdFromFaturaAsync,
  fetchAllAsync,
  fetchAllFromFaturaAsync,
  fetchByIdAsync,
  fetchByIdFromFaturaAsync,
  fetchFilterPaginateAsync,
  gerarSubstitutivaAsync,
  inactivateByIdAsync,
  inactivateByIdFromFaturaAsync,
  licencaImportacaoThunks,
  transmissaoLiByIdAsync,
  updatePartialLi,
};
